/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Polymer: THREE.Mesh
    Metal: THREE.Mesh
  }
  materials: {
    Polymer: THREE.MeshStandardMaterial
    Metal: THREE.MeshStandardMaterial
  }
}

export default function Moka({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('assets/models/moka.gltf') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh receiveShadow castShadow geometry={nodes.Polymer.geometry} material={materials.Polymer} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
      <mesh receiveShadow castShadow geometry={nodes.Metal.geometry} material={materials.Metal} rotation={[Math.PI / 2, 0, 0]} scale={0.04} />
    </group>
  )
}

useGLTF.preload('assets/models/moka.gltf')
