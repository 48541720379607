/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Cube: THREE.Mesh
  }
  materials: {}
}

export default function HeavyDesk({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('assets/models/heavyDesk.gltf') as GLTFResult
  (nodes.Cube.material as any).color = { r: 0.3, g: 0.3, b: 0.3 }
  return (
    <group ref={group} {...props} dispose={null}>
      <mesh castShadow receiveShadow geometry={nodes.Cube.geometry} material={nodes.Cube.material} position={[0, 0.8, 0]} scale={0.75} />
    </group>
  )
}

useGLTF.preload('assets/models/heavyDesk.gltf')
