/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'
import { useBox } from '@react-three/cannon'

type GLTFResult = GLTF & {
  nodes: {
    Cube: THREE.Mesh
  }
  materials: {
    Material: THREE.MeshPhongMaterial
  }
}

export default function SeparatorWall({ ...props }: JSX.IntrinsicElements['group']) {
  const { nodes, materials } = useGLTF('/assets/models/separator-wall.gltf') as GLTFResult
  return (
    <group {...props} dispose={null}>
      <mesh receiveShadow castShadow geometry={nodes.Cube.geometry} material={materials.Material} position={[0, 1.2, -0.35]} scale={[0.15, 1.25, 0.85]} />
    </group>
  )
}

useGLTF.preload('/assets/models/separator-wall.gltf')
