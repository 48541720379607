/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Cube003: THREE.Mesh
    Cube003_1: THREE.Mesh
    Cube003_2: THREE.Mesh
    Cube003_3: THREE.Mesh
    Cube003_4: THREE.Mesh
    Cube003_5: THREE.Mesh
    Plane: THREE.Mesh
    Cube: THREE.Mesh
    Cube_1: THREE.Mesh
    Cube_2: THREE.Mesh
    Cube_3: THREE.Mesh
    Cube_4: THREE.Mesh
  }
  materials: {
    Key: THREE.MeshStandardMaterial
    BadanKeyboard: THREE.MeshStandardMaterial
    Laser: THREE.MeshStandardMaterial
    LobangCas: THREE.MeshStandardMaterial
    Hjau: THREE.MeshStandardMaterial
    TextKey: THREE.MeshStandardMaterial
    Lobang: THREE.MeshStandardMaterial
  }
}

export default function MacKeyboard({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('/assets/models/macKeyboard.gltf') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[0, 0.25, 0]} scale={[4.87, 0.18, 2]}>
        <mesh castShadow receiveShadow geometry={nodes.Cube003.geometry} material={nodes.Cube003.material} />
        <mesh castShadow receiveShadow geometry={nodes.Cube003_1.geometry} material={nodes.Cube003_1.material} />
        <mesh castShadow receiveShadow geometry={nodes.Cube003_2.geometry} material={materials.Laser} />
        <mesh castShadow receiveShadow geometry={nodes.Cube003_3.geometry} material={materials.LobangCas} />
        <mesh castShadow receiveShadow geometry={nodes.Cube003_4.geometry} material={nodes.Cube003_4.material} />
        <mesh castShadow receiveShadow geometry={nodes.Cube003_5.geometry} material={nodes.Cube003_5.material} />
      </group>
      {/* <mesh castShadow receiveShadow geometry={nodes.Plane.geometry} material={nodes.Plane.material} position={[0, 0.04, 0]} scale={8.82} /> */}
      <group position={[0.01, 0.13, -0.06]} rotation={[-Math.PI, 0, -Math.PI]} scale={[4.82, 0.13, 1.98]}>
        <mesh castShadow receiveShadow geometry={nodes.Cube.geometry} material={nodes.Cube.material} />
        <mesh castShadow receiveShadow geometry={nodes.Cube_1.geometry} material={nodes.Cube_1.material} />
        <mesh castShadow receiveShadow geometry={nodes.Cube_2.geometry} material={nodes.Cube_2.material} />
        <mesh castShadow receiveShadow geometry={nodes.Cube_3.geometry} material={materials.Lobang} />
        <mesh castShadow receiveShadow geometry={nodes.Cube_4.geometry} material={nodes.Cube_4.material} />
      </group>
    </group>
  )
}

useGLTF.preload('/assets/models/macKeyboard.gltf')
