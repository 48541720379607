/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { GLTF } from 'three-stdlib'

type GLTFResult = GLTF & {
  nodes: {
    Pastillas006: THREE.Mesh
    // Curve000: THREE.Mesh
    Plano004: THREE.Mesh
    Círculo002: THREE.Mesh
    Círculo001: THREE.Mesh
    Círculo: THREE.Mesh
    // Curve2231: THREE.Mesh
    Pastillas005: THREE.Mesh
    Pastillas004: THREE.Mesh
    Tapas001: THREE.Mesh
    Pastillas003: THREE.Mesh
    Pastillas002: THREE.Mesh
    Boton_1003: THREE.Mesh
    Boton_1002: THREE.Mesh
    Boton_1001: THREE.Mesh
    // Curve2230: THREE.Mesh
    // Curve2229: THREE.Mesh
    Círculo009: THREE.Mesh
    Cejilla: THREE.Mesh
    Mastil: THREE.Mesh
    Clavija_1002: THREE.Mesh
    Clavija_1001: THREE.Mesh
    Diapason: THREE.Mesh
    // Curve2228: THREE.Mesh
    Tapa: THREE.Mesh
    Aro: THREE.Mesh
    Boton_1: THREE.Mesh
    Clavija_1: THREE.Mesh
    Pastillas001: THREE.Mesh
    Pastillas: THREE.Mesh
    Puente: THREE.Mesh
    Tensor: THREE.Mesh
    Tapas: THREE.Mesh
  }
  materials: {
    Pastilla: THREE.MeshStandardMaterial
    Material: THREE.MeshStandardMaterial
    Botones: THREE.MeshStandardMaterial
    Tapas: THREE.MeshStandardMaterial
    SVGMat: THREE.MeshStandardMaterial
    Traste: THREE.MeshStandardMaterial
    ['Material.002']: THREE.MeshStandardMaterial
    ['Material.010']: THREE.MeshStandardMaterial
  }
}

export default function Model({ ...props }: JSX.IntrinsicElements['group']) {
  const group = useRef<THREE.Group>()
  const { nodes, materials } = useGLTF('/assets/models/gibson.gltf') as GLTFResult
  return (
    <group ref={group} {...props} dispose={null}>
      <meshPhongMaterial
        // map={textureScreen}
        transparent
        emissive={'cyan'}
      />
      <group position={[0.15, 0.08, -1.3]} rotation={[1.54, 0, -Math.PI / 2]} scale={0.1} />
      {/* <mesh castShadow receiveShadow geometry={nodes.Pastillas006.geometry} material={nodes.Pastillas006.material} position={[0, -0.11, -3.78]} rotation={[-0.36, 0, 0]} scale={[0.26, 0.07, 0.07]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Curve000.geometry} material={nodes.Curve000.material} position={[0, -0.1, -3.74]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Plano004.geometry} material={nodes.Plano004.material} position={[0, 0.16, -1.27]} rotation={[-0.07, 0, 0]} scale={[1.32, 1, 1.72]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Círculo002.geometry} material={nodes.Círculo002.material} position={[0.61, 0.17, 2.96]} rotation={[0.03, 0, -0.01]} scale={0.02} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Círculo001.geometry} material={nodes.Círculo001.material} position={[0.75, 0.23, 1.79]} rotation={[0, 0, -0.12]} scale={0.04} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Círculo.geometry} material={nodes.Círculo.material} position={[0.75, 0.23, 1.79]} rotation={[0, 0, -0.12]} scale={0.04} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Curve2231.geometry} material={nodes.Curve2231.material} position={[0.24, 0.25, 0.86]} rotation={[-0.07, 0.01, -0.09]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Pastillas005.geometry} material={nodes.Pastillas005.material} position={[0, 0.27, 1.16]} rotation={[-0.07, 0, 0]} scale={[0.26, 0.07, 0.07]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Pastillas004.geometry} material={nodes.Pastillas004.material} position={[0, 0.22, 0.31]} rotation={[-0.11, 0, 0]} scale={[0.26, 0.07, 0.07]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Tapas001.geometry} material={nodes.Tapas001.material} position={[0, 0.26, 1.16]} rotation={[-0.07, 0, 0]} scale={[0.08, 0.08, 0.09]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Pastillas003.geometry} material={nodes.Pastillas003.material} position={[0, 0.22, 1.25]} rotation={[-0.07, 0, 0]} scale={[-0.26, 0.07, 0.07]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Pastillas002.geometry} material={nodes.Pastillas002.material} position={[0, 0.21, 1.25]} rotation={[-0.07, 0, 0]} scale={[0.28, 0.08, 0.07]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Boton_1003.geometry} material={nodes.Boton_1003.material} position={[1.42, 0.15, 2.35]} scale={0.1} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Boton_1002.geometry} material={nodes.Boton_1002.material} position={[1.26, 0.16, 1.87]} rotation={[0.01, 0, -0.08]} scale={0.1} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Boton_1001.geometry} material={nodes.Boton_1001.material} position={[0.89, 0.19, 2.18]} rotation={[0.03, 0, -0.14]} scale={0.1} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Curve2230.geometry} material={nodes.Curve2230.material} position={[-0.33, 0.26, 1.81]} scale={9} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Curve2229.geometry} material={nodes.Curve2229.material} position={[0.33, 0.26, 1.81]} scale={9} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Círculo009.geometry} material={materials.Traste} position={[0, 0.16, -1.29]} rotation={[1.5, 0, -Math.PI / 2]} scale={[1, 0.95, 1]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Cejilla.geometry} material={nodes.Cejilla.material} position={[0, -0.03, -3.57]} rotation={[-0.07, 0, 0]} scale={[0.17, 0.02, 0.02]} /> */}
      <mesh castShadow receiveShadow geometry={nodes.Mastil.geometry} material={nodes.Mastil.material} position={[0, -0.36, -4.23]} rotation={[-0.37, 0, 0]} scale={[0.26, 0.06, 0.2]} />
      {/* <mesh castShadow receiveShadow geometry={nodes.Diapason.geometry} material={nodes.Diapason.material} position={[0, -0.09, -3.54]} rotation={[1.5, -Math.PI / 2, 0]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Curve2228.geometry} material={nodes.Curve2228.material} position={[0.91, 0.41, 1.64]} scale={9} /> */}
      <mesh castShadow receiveShadow geometry={nodes.Tapa.geometry} material={nodes.Tapa.material} position={[0, 0, 1.16]} scale={[1, 1.06, 1]} />
      <mesh castShadow receiveShadow geometry={nodes.Aro.geometry} material={nodes.Aro.material} position={[0, 0, 1.16]} scale={[9, 9.07, 9]} />
      {/* <mesh castShadow receiveShadow geometry={nodes.Boton_1.geometry} material={nodes.Boton_1.material} position={[1.02, 0.16, 2.67]} rotation={[0.04, 0, -0.06]} scale={0.1} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Pastillas001.geometry} material={nodes.Pastillas001.material} position={[0, 0.12, 0.23]} rotation={[-0.1, 0, 0]} scale={[0.28, 0.1, 0.07]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Pastillas.geometry} material={nodes.Pastillas.material} position={[0, 0.15, 0.22]} rotation={[-0.1, 0, 0]} scale={[0.26, 0.07, 0.07]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Puente.geometry} material={materials['Material.002']} position={[0, 0.26, 1.85]} rotation={[0.08, 0, 0]} scale={[0.1, 0.1, 0.07]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Tensor.geometry} material={materials['Material.010']} position={[0, 0.31, 1.51]} rotation={[-0.03, 0.04, 0]} scale={[0.06, 0.04, 0.06]} /> */}
      {/* <mesh castShadow receiveShadow geometry={nodes.Tapas.geometry} material={nodes.Tapas.material} position={[0, 0.2, 0.31]} rotation={[-0.1, 0, 0]} scale={[0.08, 0.08, 0.09]} /> */}
      <group>
        <mesh castShadow receiveShadow geometry={nodes.Clavija_1002.geometry} material={nodes.Clavija_1002.material} position={[-0.17, -0.43, -4.63]} rotation={[-0.38, 0.07, 0]} scale={0.02} />
        <mesh castShadow receiveShadow geometry={nodes.Clavija_1001.geometry} material={nodes.Clavija_1001.material} position={[-0.17, -0.32, -4.34]} rotation={[-0.38, 0, 0]} scale={0.02} />
        <mesh castShadow receiveShadow geometry={nodes.Clavija_1.geometry} material={nodes.Clavija_1.material} position={[-0.16, -0.2, -4.06]} rotation={[-0.38, -0.06, 0]} scale={0.02} />
      </group>

      {/* <group position={[0, 0, 0]}> */}
      <mesh castShadow receiveShadow geometry={nodes.Clavija_1002.geometry} material={nodes.Clavija_1002.material} position={[0.17, -0.43, -4.63]} rotation={[-0.38, 0.07 + Math.PI, 0]} scale={0.02} />
      <mesh castShadow receiveShadow geometry={nodes.Clavija_1001.geometry} material={nodes.Clavija_1001.material} position={[0.17, -0.32, -4.34]} rotation={[-0.38, 0 + Math.PI, 0]} scale={0.02} />
      <mesh castShadow receiveShadow geometry={nodes.Clavija_1.geometry} material={nodes.Clavija_1.material} position={[0.16, -0.2, -4.06]} rotation={[-0.38, -0.06 + Math.PI, 0]} scale={0.02} />
      {/* </group> */}
    </group>
  )
}

useGLTF.preload('/assets/models/gibson.gltf')
